<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'card-header']">
                        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">{{ $t('analytic.lang_singleanalyse') }}</div>
                    </v-card-title>
                    <v-card-text class="pt-4">
                        <v-row>
                            <v-col class="pb-0 mb-0" cols="12" md="4" sm="12">
                              <v-select :items="cashierIDs" :label="$t('accounting.lang_reportForCashierID')" class="mx-auto" dense outlined
                                        v-model="cashier_id" multiple/>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="mt-0 pt-0" cols="12" sm="6">
                                <v-text-field dense :label="$t('generic.lang_from')" outlined type="date" v-model="date_start"/>
                            </v-col>

                            <v-col class="mt-0 pt-0" cols="12" sm="6">
                                <v-text-field dense :label="$t('generic.lang_till')" outlined type="date" v-model="date_end"/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="6">
                <PreSelectItemCard :selected-item="firstSelected" @selected="selectedFirst" @removeSelection="firstSelected = null"></PreSelectItemCard>
            </v-col>

            <v-col cols="12" md="6">
                <PreSelectItemCard :selected-item="secondSelected" @selected="selectedSecond" @removeSelection="secondSelected = null"></PreSelectItemCard>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {mapState} from "vuex";
import PreSelectItemCard from "@/components/analytics/warewanlytics/singleAnalyse/PreSelectItemCard";

export default {
    name: "PreSelect",

    components: {
        PreSelectItemCard
    },

    computed: {
      ...mapState("cashierIDs",{"cashierIDs":state=>state.availableCashierIDs}),
      ...mapState("api/auth",{"currentID":state=>state.cashierID}),
    },

    data() {
        return {
            cashier_id: [],
            date_start: "",
            date_end: "",
            firstSelected: null,
            secondSelected: null
        }
    },

    methods: {
        selectedFirst(data) {
            this.firstSelected = data;
        },
        selectedSecond(data) {
            this.secondSelected = data;
        }
    },
  mounted() {
    this.cashier_id.push(this.currentID);
  }
}
</script>